<template>
  <vxe-column
    :field="dynamicField.field_name"
    :title="dynamicField.title"
    :edit-render="editRender"
    show-overflow="ellipsis"
    width="300"
  >
    <template #edit="scope">
      <vxe-input v-model="scope.row[dynamicField.field_name]" type="text" @change="tableRef.updateStatus(scope)"/>
    </template>
  </vxe-column>
</template>

<script>
export default {
  name: "Field",
  props: {
    dynamicField: {
      type: Object,
      require: true,
    },
    tableRef: {
      require: true,
    }
  },
  data: () => ({
    editRender: {
      autofocus: '.myinput'
    },
  })
}
</script>

<style scoped>

</style>
